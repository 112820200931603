<template>
  <div>
    <v-dialog v-model="dialogs.edit" max-width="900">
      <v-card>
        <v-card-title> Edit </v-card-title>
        <v-container>
          <SystemEditForm
            @submit="dialogs.edit = false"
            @cancel="dialogs.edit = false"
          ></SystemEditForm>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.delete" max-width="400">
      <delete-confirmation @delete="processDelete" @cancel="dialogs.delete = false">
      </delete-confirmation>
    </v-dialog>
    <base-data-table :items="systemsComputed" :headers="headers" item-key="index">
      <template v-slot:[`item.action`]="{ item }">
        <UpdateDelete @update="showEdit(item.index)" @delete="showDelete(item.index)" />
      </template>
    </base-data-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import _ from 'lodash';
import solar from '@/mixins/solar';
import SystemEditForm from './SystemEditForm.vue';
export default {
  mixins: [solar],
  components: {
    SystemEditForm
  },
  components: {
    DeleteConfirmation: () => import('@/components/Core/DeleteConfirmation.vue'),
    SystemEditForm: () => import('./SystemEditForm.vue'),
    UpdateDelete: () => import('@/components/Feature/UpdateDelete.vue')
  },
  data: () => ({
    headers: [
      { text: 'Approved', value: 'approved' },
      { text: 'kW', value: 'kw', align: 'center' },
      { text: 'Panels', value: 'numberOfPanels', align: 'center' },
      { text: '$ Cost', value: 'cost', align: 'center' },
      { text: 'Where', value: 'where', align: 'left' },
      { text: 'Actions', value: 'action', align: 'center' }
    ],
    dialogs: {
      edit: false,
      delete: false
    }
  }),
  computed: {
    ...mapGetters({
      systems: 'solar/systems',
      selected: 'solar/selected'
    }),
    systemsComputed() {
      let res = this.copyObject(this.systems);
      res.map(e => {
        if (e.finalBuildingDate && e.finalWiringDate) {
          e.approved = _.max([new Date(e.finalBuildingDate), new Date(e.finalWiringDate)]);
        } else if (e.finalBuildingDate) {
          e.approved = new Date(e.finalBuildingDate);
        } else if (e.finalWiringDate) {
          e.approved = new Date(e.finalWiringDate);
        } else {
          e.approved = '';
        }
        if (e.approved) {
          e.approved = e.approved.toISOString().split('T')[0];
        }

        if (e.actualCost) {
          e.cost = Number(e.actualCost) || '';
        } else {
          e.cost = Number(e.estimatedCost) || '';
        }

        return e;
      });

      return res;
    }
  },
  methods: {
    ...mapMutations({
      removeSystem: 'solar/removeSystem',
      setSelectedSystem: 'solar/setSelectedSystem'
    }),
    showEdit(index) {
      this.setSelectedSystem(index);
      this.dialogs.edit = true;
    },
    showDelete(index) {
      this.setSelectedSystem(index);
      this.dialogs.delete = true;
    },
    async processDelete() {
      this.removeSystem();
      await this.saveSolarData(this.$route.params.id);
      this.dialogs.delete = false;
      this.updateMetrics({ feature: 'solar', action: 'delete' });
    }
  }
};
</script>

<style></style>
