<template>
  <v-form ref="form" v-model="valid" @submit.prevent="submitData">
    <v-row>
      <v-col cols="4" sm="3" md="3">
        <v-text-field
          autofocus
          dense
          outlined
          label="Permit #"
          v-model="formData.bp"
        ></v-text-field>
      </v-col>

      <v-col cols="8" sm="6" md="6">
        <v-text-field
          dense
          outlined
          label="Company/Builder"
          v-model="formData.builder"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="3" class="row-mt-xs-3">
        <date-field dense v-model="formData.dateOfIssue" label="Issued"></date-field>
      </v-col>
    </v-row>
    <v-row class="row-mt-3">
      <v-col cols="6" sm="3">
        <v-text-field
          dense
          outlined
          label="Estimated Cost"
          v-model="formData.estimatedCost"
        ></v-text-field>
      </v-col>

      <v-col cols="6" sm="3">
        <v-text-field outlined dense label="Fee" v-model="formData.fee"></v-text-field>
      </v-col>

      <v-col cols="6" sm="3" class="row-mt-xs-3">
        <v-text-field dense outlined label="Owner" v-model="formData.owner"></v-text-field>
      </v-col>

      <v-col cols="6" sm="3" class="row-mt-xs-3">
        <v-text-field
          dense
          outlined
          label="Actual Total Cost"
          v-model="formData.actualCost"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="row-mt-3">
      <v-col cols="6" sm="3">
        <v-text-field
          dense
          outlined
          type="number"
          label="Kilowatts"
          v-model="formData.kw"
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          outlined
          dense
          type="number"
          label="Panels"
          prefix="#"
          v-model="formData.numberOfPanels"
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="3" class="row-mt-xs-3">
        <v-select
          dense
          outlined
          label="Where"
          :rules="rules.requiredRule"
          v-model="formData.where"
          :items="options.where"
        >
        </v-select>
      </v-col>
      <v-col cols="6" sm="3" class="row-mt-xs-3">
        <base-combobox
          dense
          outlined
          label="Type of work"
          :items="options.typesOfWork"
          v-model="formData.typeOfWorkCode"
        ></base-combobox>
        <!-- <v-text-field
          outlined
          label="Type of work"
          v-model="formData.typeOfWorkCode"
        ></v-text-field> -->
      </v-col>
    </v-row>
    <v-row class="row-mt-3">
      <v-col cols="12">
        <v-textarea
          outlined
          dense
          auto-grow
          rows="2"
          label="Description"
          v-model="formData.typeOfWorkDescription"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row class="row-mt-3">
      <v-col cols="6" sm="3">
        <date-field
          dense
          v-model="formData.finalBuildingDate"
          label="Final Building Approval"
        ></date-field>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          dense
          outlined
          v-model="formData.finalBuildingApproval"
          :label="smallScreen ? 'By' : 'Final Building Approval By'"
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="3">
        <date-field
          dense
          v-model="formData.finalWiringDate"
          label="Final Wiring Approval"
        ></date-field>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          dense
          outlined
          v-model="formData.finalWiringApproval"
          :label="smallScreen ? 'By' : 'Final Wiring Approval By'"
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="text-center">
      <FormAction @click="submitData()" @cancel="cancel()" :update="update" />
    </div>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';
import rules from '@/mixins/rules';
export default {
  mixins: [rules],
  props: {
    submitText: {
      type: String,
      default: 'Save'
    },
    submitIcon: {
      type: String,
      default: 'mdi-content-save'
    },
    update: {
      type: Boolean,
      default: false
    },
    value: {}
  },
  components: {
    DateField: () => import('@/components/Core/DateField.vue'),
    FormAction: () => import('@/components/Feature/FormAction.vue')
  },
  data: () => ({
    defaultFormData: {
      dateOfIssue: '',
      finalBuildingDate: '',
      finalWiringDate: '',
      where: 'Roof',
      typeOfWorkCode: 'Solar'
    },
    origData: {},
    formData: {},
    valid: true
  }),
  computed: {
    smallScreen() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    ...mapGetters({
      options: 'solar/options'
    })
  },
  beforeMount() {
    if (!this.value) {
      this.origData = this.copyObject(this.defaultFormData);
      this.formData = this.copyObject(this.defaultFormData);
    }
  },
  mounted() {
    this.onDataChange();
  },
  methods: {
    resetForm() {
      this.formData = this.copyObject(this.origData);
      this.$refs.form.resetValidation();
    },
    onDataChange() {
      if (this.value) {
        this.origData = this.copyObject(this.value);
        this.defaultFormData = this.copyObject(this.value);
        this.formData = this.copyObject(this.value);
      }
    },
    submitData() {
      this.$refs.form.validate();
      if (this.valid) {
        if (!this.value) {
          this.formData.timestampCreated = new Date();
        }
        this.formData.timestampModified = new Date();
        this.submit();
      }
    },
    submit() {
      this.$emit('submit', this);
    },
    cancel() {
      this.resetForm();
      this.$emit('cancel');
    }
  },
  watch: {
    value() {
      this.onDataChange();
    }
  }
};
</script>

<style></style>
