import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"900"},model:{value:(_vm.dialogs.edit),callback:function ($$v) {_vm.$set(_vm.dialogs, "edit", $$v)},expression:"dialogs.edit"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Edit ")]),_c(VContainer,[_c('SystemEditForm',{on:{"submit":function($event){_vm.dialogs.edit = false},"cancel":function($event){_vm.dialogs.edit = false}}})],1)],1)],1),_c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.dialogs.delete),callback:function ($$v) {_vm.$set(_vm.dialogs, "delete", $$v)},expression:"dialogs.delete"}},[_c('delete-confirmation',{on:{"delete":_vm.processDelete,"cancel":function($event){_vm.dialogs.delete = false}}})],1),_c('base-data-table',{attrs:{"items":_vm.systemsComputed,"headers":_vm.headers,"item-key":"index"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('UpdateDelete',{on:{"update":function($event){return _vm.showEdit(item.index)},"delete":function($event){return _vm.showDelete(item.index)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }