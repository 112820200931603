<template>
  <div>
    <SystemBaseForm
      :value="data"
      @submit="onUpdate"
      update
      submitText="Update"
      @cancel="onCancel"
    ></SystemBaseForm>
  </div>
</template>
<script>
import SystemBaseForm from './SystemBaseForm';
import { mapGetters, mapMutations } from 'vuex';
import solar from '@/mixins/solar';
export default {
  mixins: [solar],
  components: {
    SystemBaseForm
  },
  computed: {
    ...mapGetters({
      selectedSystem: 'solar/selectedSystem',
      selectedSystemData: 'solar/selectedSystemData'
    }),
    data() {
      if (this.selectedSystemData) {
        return this.copyObject(this.selectedSystemData);
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapMutations({
      updateSystem: 'solar/updateSystem'
    }),
    onUpdate(ref) {
      this.updateSystem(this.copyObject(ref.formData));
      this.saveSolarData(this.$route.params.id);
      this.$emit('submit');

      this.updateMetrics({ feature: 'solar', action: 'update' });
    },
    onCancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style></style>
